import React from 'react';
import { Grid, Typography, Divider, SvgIconProps } from '@material-ui/core';
import VehicleIcon from '@material-ui/icons/DriveEta';
import AgentIcon from '@material-ui/icons/EmojiPeople';
import EmployeeIcon from '@material-ui/icons/PeopleAlt';
import ClientIcon from '@material-ui/icons/AccountCircle';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import theme from 'theme';
import { format } from 'date-fns';
import { Skeleton } from '@material-ui/lab';

interface Props {
  isLoading: boolean;
  generalSettingInfo: GeneralSettingInfo;
  tenantPlanDetail: TentantPlantDetail;
}

const GeneralInfo: React.FC<Props> = props => {
  const { isLoading, generalSettingInfo, tenantPlanDetail } = props;
  const { key, numberOfLicense, subscriptExpDate, createdAt, planType } = tenantPlanDetail;

  const renderInfo = (Icon: React.ComponentType<SvgIconProps>, title: string, value: any, divider: boolean) => {
    return (
      <>
        <Grid item xs={6} sm={2}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={3}>
              <Icon color='primary' fontSize='large' />
            </Grid>
            <Grid item xs={9}>
              <Typography variant='subtitle1'>{title}</Typography>
              {isLoading ? <Skeleton width={'50%'} /> : <Typography variant='h4'>{value}</Typography>}
            </Grid>
          </Grid>
        </Grid>
        {divider && <Divider orientation='vertical' flexItem />}
      </>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={2} justify='space-between'>
          {renderInfo(VehicleIcon, 'Active Vehicles', generalSettingInfo.activeVehicle, true)}
          {renderInfo(EmployeeIcon, 'Active Technicians', generalSettingInfo.activeTechnician, true)}
          {renderInfo(AgentIcon, 'Agents', generalSettingInfo.agent, true)}
          {renderInfo(ClientIcon, 'Clients', generalSettingInfo.client, false)}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1'>Your Product Plan Package</Typography>
            {isLoading ? (
              <Skeleton width={'50%'} height={50} style={{ marginBottom: 16 }} />
            ) : (
              <Typography style={{ fontSize: 20, fontWeight: 'bold', margin: '8px 0' }}>
                {planType} Plan Package <VerifiedIcon color='primary' />
              </Typography>
            )}
            <Grid container>
              <Grid item xs={3} component='label' style={{ marginBottom: theme.spacing(1) }}>
                <Typography variant='subtitle2'>
                  <span>&#10003;</span> Subscription Code
                </Typography>
              </Grid>
              <Grid item xs={3} component='label'>
                {isLoading ? (
                  <Skeleton width={'50%'} />
                ) : (
                  <Typography variant='subtitle2'> : {tenantPlanDetail ? `Simplify/${key}` : '-'}</Typography>
                )}
              </Grid>
              <Grid item xs={3} component='label' style={{ marginBottom: theme.spacing(1) }}>
                <Typography variant='subtitle2'>
                  <span>&#10003;</span> Sign up On
                </Typography>
              </Grid>
              <Grid item xs={3} component='label'>
                {isLoading ? (
                  <Skeleton width={'50%'} />
                ) : (
                  <Typography variant='subtitle2'>: {createdAt ? format(new Date(createdAt), 'dd MMM yyyy') : '-'}</Typography>
                )}
              </Grid>
              <Grid item xs={3} component='label' style={{ marginBottom: theme.spacing(1) }}>
                <Typography variant='subtitle2'>
                  <span>&#10003;</span> Number of Licenses
                </Typography>
              </Grid>
              <Grid item xs={3} component='label'>
                {isLoading ? <Skeleton width={'50%'} /> : <Typography variant='subtitle2'> : {tenantPlanDetail ? numberOfLicense : '-'}</Typography>}
              </Grid>
              <Grid item xs={3} component='label'>
                <Typography variant='subtitle2'>
                  <span>&#10003;</span> Expire On
                </Typography>
              </Grid>
              <Grid item xs={3} component='label'>
                {isLoading ? (
                  <Skeleton width={'50%'} />
                ) : (
                  <Typography variant='subtitle2'> : {subscriptExpDate ? format(new Date(subscriptExpDate), 'dd MMM yyyy') : '-'}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralInfo;
