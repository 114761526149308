enum SettingPageContents {
  GeneralSetting = 'General',
  Company = 'Company',
  Agents = 'Agents',
  Employees = 'Employees',
  Vehicles = 'Vehicles',
  Services = 'Service Items',
  Skills = 'Skills',
  EmailTemplates = 'Email Templates',
  BrandsTemplates = 'Brand Templates',
  ServicesTemplates = 'Quotation Templates',
  CheklistTemplates = 'Checklist Templates',
  JobNotesTemplates = 'Job Note Templates',
  JobLabelsTemplates = 'Job Label Templates',
  AppLog = 'App Logs'
}

export default SettingPageContents;
