import React, { useState, useEffect } from 'react';
import { withStyles, createStyles, Button, Typography, Grid, makeStyles, Theme, TextField, InputAdornment } from '@material-ui/core';

import { grey } from '@material-ui/core/colors';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import axios, { CancelTokenSource } from 'axios';
import { GET_SETTING_UPDATE_BASE_URL } from 'constants/url';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LoadingButtonIndicator from 'components/LoadingButtonIndicator';
import CustomizedDialog from 'components/CustomizedDialog';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  isLoading: boolean;
  reminderIdWA: number;
  remiderDayWA: number;
  reminderIsActiveWA: boolean;
  setReminderDayWA: React.Dispatch<React.SetStateAction<number>>;
  setReminderIsActiveWA: React.Dispatch<React.SetStateAction<boolean>>;
  reminderIdEmail: number;
  remiderDayEmail: number;
  reminderIsActiveEmail: boolean;
  setReminderDayEmail: React.Dispatch<React.SetStateAction<number>>;
  setReminderIsActiveEmail: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenSnackbar: (type: 'success' | 'error', message: string) => void;
}

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface IProps extends SwitchProps {
  classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1)
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#53A0BE',
          opacity: 1,
          border: 'none'
        }
      },
      '&$focusVisible $thumb': {
        color: '#53A0BE',
        border: '6px solid #fff'
      }
    },
    thumb: {
      width: 24,
      height: 24
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    },
    checked: {},
    focusVisible: {}
  })
)(({ classes, ...props }: IProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  paddingRight: {
    paddingRight: theme.spacing(1)
  },
  marginGrid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  secondText: {
    color: grey[500],
    display: 'flex'
  }
}));

const JobReminderPage: React.FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoading,
    reminderIdWA,
    remiderDayWA,
    reminderIsActiveWA,
    setReminderDayWA,
    setReminderIsActiveWA,
    reminderIdEmail,
    remiderDayEmail,
    reminderIsActiveEmail,
    setReminderDayEmail,
    setReminderIsActiveEmail,
    handleOpenSnackbar
  } = props;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isEditWA, setIsEditWA] = useState<boolean>(false);
  const [isEditEmail, setIsEditEmail] = useState<boolean>(false);
  const [dayWA, setDayWA] = useState<number>(0);
  const [dayEmail, setDayEmail] = useState<number>(0);
  const [statusGlobalWhatsApp, setStatusGlobalWhatsApp] = useState<boolean>(false);
  const [statusGlobalEmail, setStatusGlobalEmail] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const { reminderIsActiveWA, reminderIsActiveEmail, remiderDayWA, remiderDayEmail } = props;

    setDayWA(remiderDayWA);
    setDayEmail(remiderDayEmail);
    setStatusGlobalWhatsApp(reminderIsActiveWA);
    setStatusGlobalEmail(reminderIsActiveEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleCancelWA = () => {
    setIsEditWA(!isEditWA);
    setDayWA(remiderDayWA);
    setStatusGlobalWhatsApp(reminderIsActiveWA);
  };

  const handleCancelEmail = () => {
    setIsEditEmail(!isEditEmail);
    setDayEmail(remiderDayEmail);
    setStatusGlobalEmail(reminderIsActiveEmail);
  };

  const handleSubmit = async (value: string) => {
    setIsProcessing(true);
    try {
      let cancelTokenSource: CancelTokenSource;
      cancelTokenSource = axios.CancelToken.source();

      if (value === 'wa') {
        const { data } = await axios.put(
          `${GET_SETTING_UPDATE_BASE_URL(reminderIdWA)}`,
          { value: dayWA, isActive: statusGlobalWhatsApp },
          { cancelToken: cancelTokenSource.token }
        );
        setIsEditWA(!isEditWA);
        setReminderDayWA(dayWA);
        setReminderIsActiveWA(data.isActive);
      } else {
        const { data } = await axios.put(
          `${GET_SETTING_UPDATE_BASE_URL(reminderIdEmail)}`,
          { value: dayEmail, isActive: statusGlobalEmail },
          { cancelToken: cancelTokenSource.token }
        );
        setIsEditEmail(!isEditEmail);
        setReminderDayEmail(dayEmail);
        setReminderIsActiveEmail(data.isActive);
      }
      setOpenConfirm(false);
      handleOpenSnackbar('success', 'Successfully updated setting data.');
    } catch (err) {
      console.log(err);
      handleOpenSnackbar('error', 'Error update setting data.');
    }
    setIsProcessing(false);
  };

  const handleChangeStatusEmailReminder = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      setOpenConfirm(true);
    } else {
      setStatusGlobalEmail(!statusGlobalEmail);
    }
  };

  const handleChangeStatusWhatsAppReminder = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      setOpenConfirm(true);
    } else {
      setStatusGlobalWhatsApp(!statusGlobalWhatsApp);
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems='center' style={{ marginBottom: 16 }}>
        <Grid item xs={7}>
          <Typography variant='h5'>WhatsApp Appointment Confirmation</Typography>
          <Typography variant='body1' className={classes.secondText}>
            Settings for Automatic WhatsApp Messages to Your Clients Before Upcoming Jobs
          </Typography>
        </Grid>
        <Grid item container spacing={1} xs={5}>
          <Grid container spacing={2} justify='space-between' alignItems='center'>
            <Grid item>
              <Typography variant='body1' className={classes.secondText}>
                Status
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<IOSSwitch checked={statusGlobalWhatsApp} onChange={handleChangeStatusWhatsAppReminder} name='statusGlobalWhatsApp' />}
                disabled={!isEditWA}
                labelPlacement='end'
                label={statusGlobalWhatsApp ? 'Active' : 'Inactive'}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='dayWA'
              label='set days'
              margin='dense'
              disabled={!isEditWA}
              value={dayWA}
              onChange={event => setDayWA(Number(event.target.value))}
              onBlur={event => {
                if (Number(event.target.value) < 1) {
                  setDayWA(1);
                } else if (Number(event.target.value) > 99) {
                  setDayWA(99);
                } else {
                  setDayWA(Number(event.target.value));
                }
              }}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                endAdornment: <InputAdornment position='end'>Day(s)</InputAdornment>,
                inputProps: { min: 1, max: 99 }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {isEditWA ? (
              <Grid container spacing={1}>
                <Grid item xs={6} className={classes.paddingRight}>
                  <Button fullWidth variant='contained' disableElevation onClick={handleCancelWA}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant='contained' disableElevation color='primary' onClick={() => handleSubmit('wa')}>
                    Save
                    <LoadingButtonIndicator isLoading={isProcessing} />
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button fullWidth variant='contained' disableElevation color='primary' onClick={() => setIsEditWA(!isEditWA)}>
                <EditIcon /> Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={7}>
          <Typography variant='h5'>Email Appointment Reminder</Typography>
          <Typography variant='body1' className={classes.secondText}>
            Settings for Automatic Email to Your Clients Before Upcoming Jobs
          </Typography>
        </Grid>
        <Grid item container spacing={1} xs={5}>
          <Grid container spacing={2} justify='space-between' alignItems='center'>
            <Grid item>
              <Typography variant='body1' className={classes.secondText}>
                Status
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<IOSSwitch checked={statusGlobalEmail} onChange={handleChangeStatusEmailReminder} name='statusGlobalEmail' />}
                disabled={!isEditEmail}
                labelPlacement='end'
                label={statusGlobalEmail ? 'Active' : 'Inactive'}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='dayEmail'
              label='set days'
              margin='dense'
              disabled={!isEditEmail}
              value={dayEmail}
              onChange={event => setDayEmail(Number(event.target.value))}
              onBlur={event => {
                if (Number(event.target.value) < 1) {
                  setDayEmail(1);
                } else if (Number(event.target.value) > 99) {
                  setDayEmail(99);
                } else {
                  setDayEmail(Number(event.target.value));
                }
              }}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                endAdornment: <InputAdornment position='end'>Day(s)</InputAdornment>,
                inputProps: { min: 1, max: 99 }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {isEditEmail ? (
              <Grid container spacing={1}>
                <Grid item xs={6} className={classes.paddingRight}>
                  <Button fullWidth variant='contained' disableElevation onClick={handleCancelEmail}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant='contained' disableElevation color='primary' onClick={() => handleSubmit('email')}>
                    Save
                    <LoadingButtonIndicator isLoading={isProcessing} />
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button fullWidth variant='contained' disableElevation color='primary' onClick={() => setIsEditEmail(!isEditEmail)}>
                <EditIcon /> Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {openConfirm && (
        <CustomizedDialog
          isLoading={isProcessing}
          open={openConfirm}
          isConfirmation
          variant='warning'
          title={
            isEditWA
              ? 'Are you sure want to Inactive WhatsApp Appointment Confirmation?'
              : 'Are you sure want to Inactive Email Appointment Confirmation?'
          }
          message=''
          primaryButtonLabel='Ok'
          secondaryButtonLabel='Cancel'
          primaryActionButton={() => {
            isEditWA ? setStatusGlobalWhatsApp(!statusGlobalWhatsApp) : setStatusGlobalEmail(!statusGlobalEmail);
            setOpenConfirm(false);
          }}
          secondaryActionButton={() => setOpenConfirm(false)}
          handleClose={() => setOpenConfirm(false)}
        />
      )}
    </>
  );
};

export default JobReminderPage;
