import { FC, useState, useEffect } from 'react';
import { Grid, TextField, Theme, Typography, Button, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingButtonIndicator from 'components/LoadingButtonIndicator';
import { GET_EDIT_INVOICE_URL } from 'constants/url';
import axios from 'axios';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, isValid } from 'date-fns';

interface Props {
  invoice: InvoiceDetailModel;
  updateIndividual(value: any): void;
  handleClose(): void;
  handleSnackbar: (variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: 'auto',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%'
  },
  contentGrid: {
    padding: theme.spacing(2),
    maxHeight: 600,
    overflow: 'auto'
  },
  required: {
    color: 'red'
  }
}));

const EditDetailForm: FC<Props> = props => {
  const classes = useStyles();
  const { invoice, updateIndividual, handleSnackbar, handleClose } = props;

  const [isLoading, setIsloading] = useState<boolean>(false);
  const [termEnd, setTermEnd] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [remarks, setRemarks] = useState<string>('');
  const [invoiceDate, setInvoiceDate] = useState<Date>(new Date());
  const [invoiceDateError, setInvoiceDateError] = useState<string>('');

  useEffect(() => {
    if (!invoice) {
      return;
    }

    const { termEnd, invoiceDate, dueDate, invoiceRemarks } = invoice;
    setTermEnd(termEnd);
    setInvoiceDate(invoiceDate);
    setDueDate(dueDate);
    setRemarks(invoiceRemarks);
  }, [invoice]);

  const handleChangeInvoiceDate = (date: Date | null) => {
    if (date && isValid(date)) {
      setInvoiceDate(date);
      setInvoiceDateError('');
    } else {
      setInvoiceDateError('Please input invoice Date');
    }
  };

  const handleTermEndChange = (date: Date | null) => {
    if (date && isValid(date)) {
      setTermEnd(format(date, 'yyyy-MM-dd'));
    }
  };

  const handleSubmit = async () => {
    setIsloading(true);

    try {
      const { data } = await axios.put(`${GET_EDIT_INVOICE_URL(invoice!.id)}`, {
        termEnd,
        invoiceDate,
        dueDate,
        remarks
      });
      updateIndividual(data);
      handleSnackbar('success', 'Successfully update invoice');
      handleClose();
    } catch (err) {
      handleSnackbar('error', 'Failed update invoice');
    }
    setIsloading(false);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.contentGrid} alignItems='center'>
        <Grid item xs={12} sm={4}>
          <Typography variant='h6'>
            Invoice Date <span className={classes.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              clearable
              required
              fullWidth
              id='invoiceDate'
              label='Invoice Date'
              margin='dense'
              value={invoiceDate}
              variant='dialog'
              inputVariant='outlined'
              format='dd-MM-yyyy'
              onChange={handleChangeInvoiceDate}
              error={invoiceDateError ? true : false}
              helperText={invoiceDateError}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              InputAdornmentProps={{ position: 'start' }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='h6'>
            Term End <span className={classes.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              clearable
              required
              fullWidth
              id='termEnd'
              margin='dense'
              value={termEnd}
              variant='dialog'
              inputVariant='outlined'
              format='dd-MM-yyyy'
              onChange={handleTermEndChange}
              InputAdornmentProps={{ position: 'start' }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='h6'>Due Invoice</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField margin='dense' fullWidth id='invoiceDue' variant='outlined' value={dueDate} onChange={event => setDueDate(event.target.value)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='h6'>Remarks</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            margin='dense'
            fullWidth
            id='invoiceRemarks'
            variant='outlined'
            multiline
            rows={5}
            value={remarks}
            onChange={event => setRemarks(event.target.value)}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button variant='contained' disableElevation disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' color='primary' disableElevation disabled={isLoading} onClick={handleSubmit}>
          Save
          <LoadingButtonIndicator isLoading={isLoading} />
        </Button>
      </DialogActions>
    </>
  );
};

export default EditDetailForm;
